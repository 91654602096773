// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const HOST_URL1 = "http://81.82.247.124:8880/v1.0";
// const HOST_URL2 = "http://81.82.247.124:8883/v1.0";
export const environment = {
  production: false,
  apiUrl: 'http://82.165.177.10:8880/v1.0',//  apiUrl: 'http://192.168.19.25:8880/v1.0'  //  
  licenceUrl: "https://www.boa-platform.com/licence"
  // licenceUrl: "http://licence.localhost"
};

/*http://localhost:4200
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.









