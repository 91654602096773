export const ABO_ICONS:any = {
    'A' : 'abo-phone',
    '%' : 'abo-printer',
    '"' : 'abo-linea-basic-10-17',
    '#' : 'abo-linea-basic-10-15',
    '$' : 'abo-linea-basic-elaboration-10-3',
    '1' : 'abo-linea-basic-10-6',
    '2' : 'abo-linea-ecommerce-10-1',
    '3' : 'abo-linea-ecommerce-10-2',
    '4' : 'abo-linea-ecommerce-10-3',
    '5' : 'abo-linea-software-10',
    '6' : 'abo-linea-basic-10-8',
    '7' : 'abo-linea-basic-10-9',
    '9' : 'abo-linea-basic-elaboration-10',

    'D' : 'abo-linea-basic-10-7',
    'E' : 'abo-computer-imac-2',
    'G' : 'abo-euro',
    'J' : 'abo-linea-basic-10-13',
    'K' : 'abo-belgium',
    'L' : 'abo-spreadsheet6',
    'O' : 'abo-scanning2',
    'P' : 'abo-filter-modified',
    'S' : 'abo-fax3',
    'R' : 'abo-repair5',
    'U' : 'abo-mod-register',
    'V' : 'abo-linea-ecommerce-10',
    'W' : 'abo-mod-barcode',
    'Y' : 'abo-linea-basic-10-10',
    'a' : 'abo-uniE007',
    'b' : 'abo-uniE00E',
    'c' : 'abo-uniE00F',
    'd' : 'abo-uniE00B',
    'e' : 'abo-linea-arrows-10-4',
    'f' : 'abo-uniE01C',
    'g' : 'abo-linea-basic-10',
    'h' : 'abo-linea-basic-10-3',
    'j' : 'abo-linea-basic-10-2',
    'k' : 'abo-linea-arrows-10',
    'l' : 'abo-eid-invert-2',
    '/' : 'abo-man-people-streamline-user',
    'n' : 'abo-mod-supplier',
    'o' : 'abo-scan',
    'p' : 'abo-linea-arrows-10-5',
    'q' : 'abo-45',
    's' : 'abo-home152',
    't' : 'abo-nbb',
    'y' : 'abo-open79',
    '{' : 'abo-linea-search_mini',
    'x' : 'abo-document-add',
    'Z' : 'abo-document-file-pdf',
    'M' : 'abo-document-file-doc',
    'N' : 'abo-document-file-xls',
    'r' : 'abo-document',
    '!' : 'abo-documents',
    'C' : 'abo-search',
    '[' : 'abo-calendar',
    'H' : 'abo-camera',
    '=' : 'abo-notebook-text',
    'X' : 'abo-mail-envelope-closed',
    ',' : 'abo-folder-search',
    'B' : 'abo-document-edit',
    'i' : 'abo-pencil',
    ')' : 'abo-balance-scale',
    '}' : 'abo-read-more',
    'I' : 'abo-list-nested',
    '8' : 'abo-list',
    '+' : 'abo-uni7C',
    '-' : 'abo-uni7E',
    '\\' : 'abo-uni5C',
    'Q' : 'abo-linea-basic-10-1'
}