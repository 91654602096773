import { NgModule } from '@angular/core';
import { ExtraOptions, Routes, RouterModule } from '@angular/router';
import { ProtectGuard } from './shared/authentication/protect.guard';
import { PublicGuard } from './shared/authentication/public.guard';

const routes: Routes = [
	{
		path: 'pages',
		canActivate: [ ProtectGuard ],
		loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
		runGuardsAndResolvers: 'always'
	},
	{
		path: 'auth',
		canActivate: [ PublicGuard ],
		loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
		runGuardsAndResolvers: 'always'
	},
	{ path: '**', redirectTo: 'auth', pathMatch: 'full' }
];

const config: ExtraOptions = {
	useHash: false,
	relativeLinkResolution: 'corrected'
};

@NgModule({
	imports: [ RouterModule.forRoot(routes, config) ],
	exports: [ RouterModule ]
})
export class AppRoutingModule {}
